<ejs-dialog [isModal]="true" [title]="'Confirmation.Title' | translate" height="auto" width="400px">
  <ng-template #header>{{ title() | translate }}</ng-template>
  <ng-template #content>
    <div>{{ text() | translate }}</div>
  </ng-template>
  <ng-template #footerTemplate>
    @if (!submitOnly()) {
      <hk-button
        (click)="decline()"
        [text]="'Basic.No' | translate"
        theme="alternate"
        width="70px"
      ></hk-button>
    }
    <hk-button (click)="accept()" [text]="'Basic.Ok' | translate" width="70px"></hk-button>
  </ng-template>
</ejs-dialog>
