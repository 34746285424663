import { Component, input, output } from '@angular/core';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonComponent } from '../button/button.component';
import { TranslatePipe } from '../../pipes/translation/translation.pipe';

@Component({
  selector: 'hk-confirm-dialog',
  imports: [DialogModule, ButtonComponent, TranslatePipe],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  text = input.required<string>();
  result = output<boolean>();
  submitOnly = input<boolean>(false);
  title = input<string>('Confirmation.Title');

  accept() {
    this.result.emit(true);
  }

  decline() {
    this.result.emit(false);
  }
}
